.homepage-blog-posts {
    padding: 0 1rem;
    .homepage-blog-posts-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 2rem;
        padding-bottom: 1rem;
        p {
            max-width: 800px;
            margin: 0;
        }
    }
    .homepage-blog-posts-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
        gap: 1rem;
        .homepage-blog-post,
        .homepage-blog-post-edit {
            padding: 1.5rem;
            padding-bottom: 1rem;
        }
        .homepage-blog-post {
            width: 100%;
            &.fade {
                opacity: 0.5;
            }

            h4,
            p {
                margin: 0;
                margin-bottom: 8px;
            }
            .image-wrapper {
                width: 100%;
                height: 200px;
                border-radius: 4px;
                overflow: hidden;
                margin-bottom: 0.5rem;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .homepage-blog-post-inputs {
            padding: 0;
            display: flex;
            flex-direction: column;
            label {
                margin-bottom: 0.25rem;
            }
            input {
                margin-bottom: 0.5rem;
            }
        }
        .blog-post-card-buttons {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            padding-top: 0.5rem;
        }
    }
}
