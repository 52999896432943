.accordion {
    .toggle-text-and-icon {
        cursor: pointer;
    }

    .toggle {
        font-size: 14px;
        margin-right: 0;
        transform: rotate(-90deg);
        transition: 0.3s;
        &.active {
            transform: rotate(0deg);
        }
    }

    .accordion-content {
        display: none;
        &.active {
            display: block;
        }
    }
}
