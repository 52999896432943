.swap-practice-widget {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1rem;

    label,
    input {
        width: 100%;
    }

    input {
        margin-top: 0.5rem;
    }
}

.manual-reimbursify-sso-widget {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    input {
        margin: 0 1rem;
        max-width: 12rem;
    }
}
