.account-attributes-table {
  .edit-rule-grid-button {
    float: left;
    margin-top: 5px;
  }
}

.account-attributes-form {
  margin-left: 50px;
  input[type="text"],
  select {
    width: 500px;
  }

  .account-attributes-field-label {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .account-attributes-value-tip {
    margin-top: 10px;
  }
}
