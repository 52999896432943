.insurance-rules-table {
  .edit-rule-grid-button {
    float: left;
    margin-top: 5px;
  }
}

.edit-insurance-rule {
  .clear-tag-selection {
    float: right;
  }
}
