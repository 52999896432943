.omni-results-modal {
    .product-icon {
        width: 16px;
    }

    .data-table {
        width: 100%;
        border-collapse: collapse;
    }

    .clickable-result-row {
        &:hover {
            cursor: pointer;
            background-color: bisque;
        }
    }

    .data-table th,
    .data-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        word-wrap: break-word;

        &:first-child {
            max-width: 5rem;
        }
    }

    td.notes {
        white-space: pre-line;
    }
}
