.toggle-both-labels {
  display: flex;
  align-items: center;
  min-width: 350px;

  label {
    flex: 1 1 200px;
  }
}

.image-selector {
  position: relative;

  .o-3 {
    opacity: 0.3;
  }

  input {
    display: none;
  }

  .controls {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 24px;
    z-index: 10;
    background: #fff;
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;

    span {
      cursor: pointer;
    }
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;

  img {
    width: 200px;
    height: 200px;
  }
}

.addable-form {
  .add {
    color: #0d797c;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 20px;
    display: inline-block;
    font-size: 1.2rem;
    background: none;
    padding: 0;

    .far {
      margin-right: 5px;
    }

    &:focus {
      color: #0d797c;
    }
  }
}

.addable-form-inner {
  display: grid;
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  margin-bottom: 20px;

  h5.addable-tag-form-field-group.header {
    margin-bottom: none;
  }

  .field {
    max-height: 50px;

    input {
      height: 40px;
    }

    .name {
      display: flex;
      align-items: center;
    }

    &.fee,
    &.length {
      max-width: 200px;
      min-width: 60px;

      input {
        padding-left: 20px;
      }
    }

    span.units {
      top: -39px;
      position: relative;
    }

    input {
      width: 100%;
    }

    &.fee {
      span.units {
        left: 10px;
      }
    }

    &.length {
      span.units {
        left: 60%;
      }
    }
  }
}

.addable-form {
  .delete {
    color: red;
    cursor: pointer;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  select {
    font-size: 16px;
    font-family: 'proxima-nova', sans-serif;
    font-weight: 400;
    padding: 0.875rem 0.75rem;
    display: inline-block;
    outline: none;
    border: 1px solid #dce0e0;
    border-radius: 6px;
    transition: border 0.2s;
    text-align: left;
    min-width: 4rem;
    background-color: white;

    &:required:invalid {
      color: gray;
    }
    option[value=''][disabled] {
      display: none;
    }
  }
}

.box {
  box-shadow: 0px 2px 8px #00000024;
  padding: 24px;
  border-radius: 6px;
}

.egrid {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 1px;
  column-gap: 1px;
}

.pronoun-form {
  label {
    color: black;
    letter-spacing: normal;
    font-weight: 400;
    margin: 8px;
  }

  .flex {
    display: flex;
  }

  .column {
    flex-direction: column;
  }

  .align-baseline {
    align-items: baseline;
  }
}

.bool-toggle {
  label.checkbox {
    width: 60px;
    height: 30px;
    float: left;
    margin-right: 5px;
  }
  label.checkbox:after {
    width: 20px;
    height: 20px;
  }

  label.real-label {
    padding-top: 5px;
    display: block;
  }
}
