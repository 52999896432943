.provider-licenses {
  .provider-license-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  input:not([type="radio"]):not([type="checkbox"]),
  .input,
  textarea {
    min-width: 0rem;
  }
}
