.google-calendar-status-container {
    .success-icon {
        color: green;
    }

    .fail-icon {
        color: red;
    }

    .card {
        border: 1px solid #ddd;
        border-radius: 8px;
        margin: 10px;
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .card-header h2 {
        margin: 0 0 10px 0;
    }

    .card-body p {
        margin: 5px 0;
    }
}
