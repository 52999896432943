.toggle-both-labels {
  display: flex;
  align-items: center;
  min-width: 350px;
}
.toggle-both-labels label {
  flex: 1 1 200px;
}

.image-selector {
  position: relative;
}
.image-selector .o-3 {
  opacity: 0.3;
}
.image-selector input {
  display: none;
}
.image-selector .controls {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
  z-index: 10;
  background: #fff;
  margin: 5px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.image-selector .controls span {
  cursor: pointer;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
}
.gallery img {
  width: 200px;
  height: 200px;
}

.addable-form .add {
  color: #0d797c;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 20px;
  display: inline-block;
  font-size: 1.2rem;
  background: none;
  padding: 0;
}
.addable-form .add .far {
  margin-right: 5px;
}
.addable-form .add:focus {
  color: #0d797c;
}

.addable-form-inner {
  display: grid;
  row-gap: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  margin-bottom: 20px;
}
.addable-form-inner h5.addable-tag-form-field-group.header {
  margin-bottom: none;
}
.addable-form-inner .field {
  max-height: 50px;
}
.addable-form-inner .field input {
  height: 40px;
}
.addable-form-inner .field .name {
  display: flex;
  align-items: center;
}
.addable-form-inner .field.fee, .addable-form-inner .field.length {
  max-width: 200px;
  min-width: 60px;
}
.addable-form-inner .field.fee input, .addable-form-inner .field.length input {
  padding-left: 20px;
}
.addable-form-inner .field span.units {
  top: -39px;
  position: relative;
}
.addable-form-inner .field input {
  width: 100%;
}
.addable-form-inner .field.fee span.units {
  left: 10px;
}
.addable-form-inner .field.length span.units {
  left: 60%;
}

.addable-form .delete {
  color: red;
  cursor: pointer;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
}
.addable-form select {
  font-size: 16px;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  padding: 0.875rem 0.75rem;
  display: inline-block;
  outline: none;
  border: 1px solid #dce0e0;
  border-radius: 6px;
  transition: border 0.2s;
  text-align: left;
  min-width: 4rem;
  background-color: white;
}
.addable-form select:required:invalid {
  color: gray;
}
.addable-form select option[value=""][disabled] {
  display: none;
}

.box {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1411764706);
  padding: 24px;
  border-radius: 6px;
}

.egrid {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 1px;
  column-gap: 1px;
}

.pronoun-form label {
  color: black;
  letter-spacing: normal;
  font-weight: 400;
  margin: 8px;
}
.pronoun-form .flex {
  display: flex;
}
.pronoun-form .column {
  flex-direction: column;
}
.pronoun-form .align-baseline {
  align-items: baseline;
}

.bool-toggle label.checkbox {
  width: 60px;
  height: 30px;
  float: left;
  margin-right: 5px;
}
.bool-toggle label.checkbox:after {
  width: 20px;
  height: 20px;
}
.bool-toggle label.real-label {
  padding-top: 5px;
  display: block;
}

.bubble {
  border: 1px solid #54657b;
  font-size: 0.8rem;
  padding: 12px;
  border-radius: 10px;
  flex: 1 1 100px;
  max-width: 300px;
  margin: 12px;
}
.bubble .bubble-line {
  display: block;
}
.bubble .bubble-type {
  margin-top: 2px;
  margin-left: 2px;
  font-weight: 500;
  letter-spacing: 0.1rem;
  margin-bottom: 6px;
  border-bottom: 1px solid black;
  display: inline-block;
}
.bubble ul {
  margin: 0;
  line-height: 1rem;
}

.provider-bubble {
  min-width: 600px;
  max-width: 600px;
}

.account-bubble {
  width: 100%;
  max-width: 100%;
}

.customer-bubble {
  min-width: 600px;
  max-width: 600px;
}

a.bubble {
  color: black;
  cursor: pointer;
  transition: all 0.15s ease;
}
a.bubble:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border: 1px solid #54657b;
}

.account-holder {
  padding: 8px;
}
.account-holder .customer-bubble-container {
  flex: 1 1 500px;
  overflow: auto;
  /*max-height: 300px;*/
}
.account-holder .user-bubble-container,
.account-holder .account-bubble-container,
.account-holder .customer-bubble-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.account-holder .account-bubble {
  border: 1px solid black;
  border-radius: 0;
  padding: 20px;
  font-size: 1rem;
  margin: 0px;
  align-self: stretch;
}

.connection {
  background: black;
  display: block;
}
.connection.vertical {
  width: 2px;
  height: 50px;
}
.connection.horizontal {
  height: 2px;
  width: 50px;
}

.association-container .options {
  max-height: 250px;
  overflow: auto;
}

.intake-export {
  display: flex;
  flex-direction: column;
}
.intake-export .box {
  max-width: 32rem;
}
.intake-export h3 {
  margin: 0;
}
.intake-export p {
  margin: 0;
}
.intake-export .intake-export-desc {
  font-weight: 600;
}
.intake-export .intake-export-text {
  font-style: italic;
  color: #6a727b;
}
.intake-export .intake-export-note {
  max-width: 32rem;
  margin-bottom: 0;
}
.intake-export button {
  max-width: 16rem;
  margin: 1rem 0;
}

.insurance-rules-table .edit-rule-grid-button {
  float: left;
  margin-top: 5px;
}

.edit-insurance-rule .clear-tag-selection {
  float: right;
}

.provider-licenses .provider-license-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 1px;
  column-gap: 1px;
}
.provider-licenses input:not([type=radio]):not([type=checkbox]),
.provider-licenses .input,
.provider-licenses textarea {
  min-width: 0rem;
}

.feature-flags table {
  width: 100%;
}
.feature-flags th {
  text-transform: lowercase;
}
.feature-flags ul,
.feature-flags ol {
  margin-left: 0;
}
.feature-flags label {
  display: inline-block;
  min-width: 9rem;
}
.feature-flags label.hide {
  display: none;
}
.feature-flags select,
.feature-flags .file-input {
  width: 18rem;
}

.calendar-container {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 20px;
  transition: 0.3s;
  position: relative;
}
.calendar-container.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 100;
  width: 100vw;
  border-radius: 0;
  height: 100vh;
}
.calendar-container.full-screen .y-scroll-container {
  height: 100vh;
}

.calendar-controlbar {
  display: flex;
  flex-direction: column;
  z-index: 1;
  top: 0;
  background: white;
}
.calendar-controlbar .date-controls button:not(.today-button),
.calendar-controlbar .fullscreen-button {
  font-size: 1rem;
  padding: 0;
  width: 32px;
  height: 32px;
}

.grid-item {
  flex: 1 1 75px;
  width: 75px;
  min-width: 75px;
}
.grid-item.day-grid {
  border-right: 1px solid lightgray;
}
.grid-item .hour-grid {
  height: 50px;
  border-bottom: 1px solid lightgray;
  width: 100%;
}

@media only screen and (max-width: 780px) {
  .grid-item {
    flex: 1 1 30px;
    width: 30px;
  }
}
.y-scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: scroll;
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.x-scroll-container {
  position: relative;
  display: flex;
}

#calendar-view,
#calendar-tz {
  width: 100px;
  height: 36px;
  padding: 0;
  font-size: 12px;
}

.date-labels {
  position: sticky;
  min-width: 100%;
  opacity: 0.92;
  top: 0;
  z-index: 4;
  background: white;
  flex: 0 0 50px;
  margin: auto;
  padding-left: 50px;
}
.date-labels .day-header {
  text-align: center;
  text-transform: uppercase;
}
.date-labels .day-header.today {
  color: #37bec3;
}
.date-labels .day-header p {
  margin-bottom: 0;
}
.date-labels .day-header p.day-of-week {
  font-weight: 200;
}
.date-labels .day-header p.date-of-month {
  font-size: 20px;
}
.date-labels .day-header p.date-of-month.today {
  border-radius: 50px;
  background-color: #37bec3;
  color: white;
  display: inline-block;
  padding: 0 8px;
}

.hour-labels {
  flex: 0 0 50px;
  background: rgba(255, 255, 255, 0.9333333333);
  height: 100%;
  position: sticky;
  margin-top: 36px;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  left: 0;
  z-index: 2;
}
.hour-labels .hour-grid {
  border-bottom: none;
  text-align: right;
  font-weight: 200;
  padding-right: 10px;
  height: 50px;
}

.calendar-block {
  position: absolute;
  color: white;
  min-height: 20px;
  line-height: 20px;
  width: 90%;
  overflow: hidden;
  text-overflow: hidden;
  font-size: 14px;
  opacity: 1;
  cursor: pointer;
  line-height: 14px;
  white-space: nowrap;
  padding: 2px;
  border-radius: 2px;
  transition: 0.2s;
}
.calendar-block.free {
  background-color: #37bec3;
  z-index: 0;
}
.calendar-block.busy {
  background-color: #ff777d;
  z-index: 1;
}
.calendar-block.appointment {
  background-color: #0d797c;
  z-index: 2;
}
.calendar-block.appointment:hover {
  min-height: 50px;
}
.calendar-block:hover {
  min-height: 65px;
  width: 100%;
  border: 1px solid white;
  transform: scale(1.02);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.231372549);
}
.calendar-block.in-the-past {
  opacity: 0.7;
  cursor: auto;
}
.calendar-block.now-indicator {
  box-shadow: none;
  border: none;
  padding: 0;
  background-color: black;
  min-height: 2px;
  z-index: 3;
  width: 100%;
}

.edit-availability-modal-interior .tab-select {
  align-items: stretch;
}
.edit-availability-modal-interior .tab-select input[value=busy]:checked + label {
  background-color: red;
}
.edit-availability-modal-interior .tab-select .container {
  width: 50%;
}
.edit-availability-modal-interior input[type=date],
.edit-availability-modal-interior input[type=time] {
  width: 50px;
}

.custom-recurrence-section {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(220, 224, 224);
}
.custom-recurrence-section input[type=number] {
  min-width: 50px;
}
.custom-recurrence-section .checkbox [type=checkbox] {
  display: none;
}
.custom-recurrence-section .checkbox [type=checkbox]:checked + label {
  color: #fff;
}
.custom-recurrence-section .checkbox [type=checkbox]:checked + label::before {
  background: #37bec3;
}
.custom-recurrence-section .checkbox [type=checkbox] + label::before {
  border-radius: 30px;
  margin-right: -24px;
  background: #dce0e0;
}
.custom-recurrence-section .checkbox [type=checkbox] + label::after {
  display: none;
}
.custom-recurrence-section .field.days-of-week {
  width: 36px;
  display: inline-block;
}
.custom-recurrence-section .recurrence-ending-container label {
  display: flex;
  height: 50px;
  align-items: center;
}
.custom-recurrence-section .recurrence-ending-container label::before, .custom-recurrence-section .recurrence-ending-container label::after {
  margin-top: 15px;
}

.appointment-item {
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 1rem;
}
.appointment-item p {
  margin: 0.2rem;
}
.appointment-item .message {
  position: relative;
  padding: 8px;
  border: 1px solid grey;
  border-radius: 8px;
  max-width: 70%;
}
.appointment-item .message .message-unread-notification {
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  position: absolute;
  background: red;
  border-radius: 26px;
}
.appointment-item .message p {
  margin-bottom: 0;
}
.appointment-item .message p.message-date {
  font-weight: 300;
  font-size: 0.9rem;
}

.appointment-view-container .box.inner-container {
  border-radius: 0px 5px;
  z-index: 1;
  position: relative;
  background: white;
}
.appointment-view-container .appointment-tab {
  border-radius: 0;
  background: white;
  color: black;
  position: relative;
  top: 0px;
  z-index: 0;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1411764706);
  border-radius: 5px 5px 0px 0px;
  opacity: 0.6;
}
.appointment-view-container .appointment-tab h5 {
  margin: 0;
}
.appointment-view-container .appointment-tab.active {
  z-index: 3;
  opacity: 1;
}
.appointment-view-container .appointment-day-group:not(:last-child) {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid lightgrey;
}

.page-link {
  text-decoration: none;
  color: #2c3643;
  cursor: pointer;
}
.page-link.active {
  border-radius: 20px;
  width: 24px;
  height: 24px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  line-height: 24px;
  background-color: #6ed0d3;
  color: white;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1019607843);
}

.pagination {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 45rem;
}

.page-button {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  padding-right: 1px;
  border: 1px solid #37bec3;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1019607843);
  cursor: pointer;
}
.page-button.forwards {
  padding-left: 3px;
}
.page-button.backwards {
  padding-right: 1px;
}

@media only screen and (max-width: 780px) {
  .appointment-view-container .appointment-item {
    flex-wrap: wrap;
    border: 1px solid lightgrey;
    padding: 8px;
  }
  .appointment-view-container .appointment-tab {
    opacity: 0.3;
  }
}
.accounts-container {
  padding: 1rem 2rem;
}
.accounts-container h2 {
  margin: 3rem 1rem 1rem;
}
.accounts-container h2:first-child {
  margin-top: 0;
}
.accounts-container p {
  margin: 0;
  margin-bottom: 1rem;
}
.accounts-container .current-subscription {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.accounts-container .current-subscription .current-subscription-details {
  min-width: 450px;
}
.accounts-container .box {
  margin: 0.5rem;
}

.account-notes {
  margin-bottom: 1rem;
}
.account-notes a,
.account-notes textarea {
  margin-bottom: 1rem;
}
.account-notes textarea {
  width: 100%;
}
.account-notes .button-container {
  align-self: flex-end;
  justify-self: flex-end;
}
.account-notes .button-container .error {
  margin-right: 0.5rem;
}
.account-notes p {
  white-space: pre-line;
  margin-top: 0;
  margin-bottom: 1rem;
}
.account-notes .gray {
  color: #8696ac;
}

.reimbursify {
  padding: 0 1rem;
}
.reimbursify h2,
.reimbursify h4,
.reimbursify h5,
.reimbursify h6 {
  margin: 0;
}
.reimbursify h2 {
  margin-top: 1.5rem;
}
.reimbursify h4 {
  margin-bottom: 1rem;
}
.reimbursify h5 {
  margin-bottom: 1rem;
}
.reimbursify h6 {
  margin-bottom: 0.5rem;
}
.reimbursify .reimbursify-code-widget code {
  padding: 0.25rem;
}
.reimbursify .reimbursify-code-widget .reimbursify-code-display {
  display: flex;
  justify-content: space-between;
}
.reimbursify .reimbursify-code-widget .reimbursify-code-widget-inputs {
  display: flex;
  justify-content: space-between;
}
.reimbursify .reimbursify-code-widget .reimbursify-code-widget-inputs form {
  padding: 0;
}
.reimbursify .reimbursify-code-widget .reimbursify-code-widget-inputs form div {
  display: flex;
  align-items: center;
}
.reimbursify .reimbursify-code-widget .reimbursify-code-widget-inputs > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.reimbursify .reimbursify-code-widget .reimbursify-code-widget-inputs > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.reimbursify .reimbursify-code-widget .reimbursify-code-widget-inputs input.checkbox:checked + label.checkbox {
  background: #37bec3;
}
.reimbursify .reimbursify-code-widget .copy-button {
  padding: 0;
  background-color: transparent;
  border: none;
  height: auto;
  color: inherit;
  font-size: 1rem;
  margin-left: 0.5rem;
}
.reimbursify .npi-numbers-widget .npi-numbers {
  margin-bottom: 1rem;
  width: 100%;
}
.reimbursify .npi-numbers-widget .npi-numbers th,
.reimbursify .npi-numbers-widget .npi-numbers td {
  text-align: center;
}
.reimbursify .npi-numbers-widget .npi-numbers th i,
.reimbursify .npi-numbers-widget .npi-numbers td i {
  opacity: 0.5;
}
.reimbursify .npi-numbers-widget form {
  padding: 0;
}
.reimbursify .npi-numbers-widget label {
  display: flex;
  flex-direction: column;
}
.reimbursify .npi-numbers-widget .npi-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.swap-practice-widget {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
}
.swap-practice-widget label,
.swap-practice-widget input {
  width: 100%;
}
.swap-practice-widget input {
  margin-top: 0.5rem;
}

.manual-reimbursify-sso-widget {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.manual-reimbursify-sso-widget input {
  margin: 0 1rem;
  max-width: 12rem;
}

.practice-provider-association .inactive-provider {
  background-color: grey;
}

.accordion .toggle-text-and-icon {
  cursor: pointer;
}
.accordion .toggle {
  font-size: 14px;
  margin-right: 0;
  transform: rotate(-90deg);
  transition: 0.3s;
}
.accordion .toggle.active {
  transform: rotate(0deg);
}
.accordion .accordion-content {
  display: none;
}
.accordion .accordion-content.active {
  display: block;
}

.omni-search-nav-input {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  align-self: center;
  outline: 2px solid #dce0e0;
  border-radius: 50px;
}

@media (max-width: 899px) {
  .omni-search-nav-input {
    display: none;
  }
}
.omni-modal {
  max-width: unset;
}

@keyframes omni-loading-spinner {
  0% {
    background: #ffffff;
  }
  12.5% {
    background: #ffffff;
  }
  12.625% {
    background: #37bec3;
  }
  100% {
    background: #37bec3;
  }
}
.omni-loading-spinner div {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #37bec3;
  animation: omni-loading-spinner 1s linear infinite;
}

.omni-loading-spinner-container {
  width: 260px;
  height: 260px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #37bec3;
  padding: 30px;
  position: absolute;
  top: 4rem;
  left: calc(50% - 180px);
}

.omni-loading-spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.omni-loading-spinner div {
  box-sizing: content-box;
}

/* originally generated by https://loading.io/ */
/* adapted from https://loading.io/asset/685657 - free with no attribution requirements. */
.omni-loading-spinner-letter-parent {
  display: grid;
  place-items: center; /* This shorthand property aligns both horizontally and vertically */
  height: 300px; /* Set the desired height */
  border: 1px solid black; /* Just for visualization */
}
.omni-loading-spinner-letter-parent .omni-loading-spinner-letter {
  color: white;
}

.omni-results-modal .product-icon {
  width: 16px;
}
.omni-results-modal .data-table {
  width: 100%;
  border-collapse: collapse;
}
.omni-results-modal .clickable-result-row:hover {
  cursor: pointer;
  background-color: bisque;
}
.omni-results-modal .data-table th,
.omni-results-modal .data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  word-wrap: break-word;
}
.omni-results-modal .data-table th:first-child,
.omni-results-modal .data-table td:first-child {
  max-width: 5rem;
}
.omni-results-modal td.notes {
  white-space: pre-line;
}

.homepage-blog-posts {
  padding: 0 1rem;
}
.homepage-blog-posts .homepage-blog-posts-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2rem;
  padding-bottom: 1rem;
}
.homepage-blog-posts .homepage-blog-posts-header p {
  max-width: 800px;
  margin: 0;
}
.homepage-blog-posts .homepage-blog-posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1rem;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post,
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post-edit {
  padding: 1.5rem;
  padding-bottom: 1rem;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post {
  width: 100%;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post.fade {
  opacity: 0.5;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post h4,
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post p {
  margin: 0;
  margin-bottom: 8px;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post .image-wrapper {
  width: 100%;
  height: 200px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post-inputs {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post-inputs label {
  margin-bottom: 0.25rem;
}
.homepage-blog-posts .homepage-blog-posts-grid .homepage-blog-post-inputs input {
  margin-bottom: 0.5rem;
}
.homepage-blog-posts .homepage-blog-posts-grid .blog-post-card-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 0.5rem;
}

.google-calendar-status-container .success-icon {
  color: green;
}
.google-calendar-status-container .fail-icon {
  color: red;
}
.google-calendar-status-container .card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.google-calendar-status-container .card-header h2 {
  margin: 0 0 10px 0;
}
.google-calendar-status-container .card-body p {
  margin: 5px 0;
}

.account-attributes-table .edit-rule-grid-button {
  float: left;
  margin-top: 5px;
}

.account-attributes-form {
  margin-left: 50px;
}
.account-attributes-form input[type=text],
.account-attributes-form select {
  width: 500px;
}
.account-attributes-form .account-attributes-field-label {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}
.account-attributes-form .account-attributes-value-tip {
  margin-top: 10px;
}