.accounts-container {
  padding: 1rem 2rem;

  h2 {
    margin: 3rem 1rem 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin: 0;
    margin-bottom: 1rem;
  }
  .current-subscription {
    width: 100%;
    display: flex;
    flex-direction: column;

    .current-subscription-details {
      min-width: 450px;
    }
  }
  .box {
    margin: 0.5rem;
  }
}

.account-notes {
  margin-bottom: 1rem;
  a,
  textarea {
    margin-bottom: 1rem;
  }

  textarea {
    width: 100%;
  }

  .button-container {
    align-self: flex-end;
    justify-self: flex-end;

    .error {
      margin-right: 0.5rem;
    }
  }

  p {
    white-space: pre-line;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .gray {
    color: #8696ac;
  }
}
