@keyframes omni-loading-spinner {
    0% {
        background: #ffffff;
    }
    12.5% {
        background: #ffffff;
    }
    12.625% {
        background: #37bec3;
    }
    100% {
        background: #37bec3;
    }
}
.omni-loading-spinner div {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #37bec3;
    animation: omni-loading-spinner 1s linear infinite;
}
.omni-loading-spinner-container {
    width: 260px;
    height: 260px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
    border: 1px solid #37bec3;
    padding: 30px;
    position: absolute;
    top: 4rem;
    left: calc(50% - 180px);
}

.omni-loading-spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.omni-loading-spinner div {
    box-sizing: content-box;
}
/* originally generated by https://loading.io/ */
/* adapted from https://loading.io/asset/685657 - free with no attribution requirements. */
.omni-loading-spinner-letter-parent {
    display: grid;
    place-items: center; /* This shorthand property aligns both horizontally and vertically */
    height: 300px; /* Set the desired height */
    border: 1px solid black; /* Just for visualization */

    .omni-loading-spinner-letter {
        color: white;
    }
}
