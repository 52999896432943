.feature-flags {
    table {
        width: 100%;
    }

    th {
        text-transform: lowercase;
    }

    ul,
    ol {
        margin-left: 0;
    }

    label {
        display: inline-block;
        min-width: 9rem;

        &.hide {
            display: none;
        }
    }

    select,
    .file-input {
        width: 18rem;
    }
}
