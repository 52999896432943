.bubble {
  border: 1px solid #54657b;
  font-size: 0.8rem;
  padding: 12px;
  border-radius: 10px;
  flex: 1 1 100px;
  max-width: 300px;
  margin: 12px;

  .bubble-line {
    display: block;
  }

  .bubble-type {
    margin-top: 2px;
    margin-left: 2px;
    font-weight: 500;
    letter-spacing: 0.1rem;
    margin-bottom: 6px;
    border-bottom: 1px solid black;
    display: inline-block;
  }

  ul {
    margin: 0;
    line-height: 1rem;
  }
}

.provider-bubble {
  min-width: 600px;
  max-width: 600px;
}

.account-bubble {
  width: 100%;
  max-width: 100%;
}

.customer-bubble {
  min-width: 600px;
  max-width: 600px;
}

a.bubble {
  color: black;
  cursor: pointer;
  transition: all 0.15s ease;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    border: 1px solid #54657b;
  }
}

.account-holder {
  .customer-bubble-container {
    flex: 1 1 500px;
    overflow: auto;
    /*max-height: 300px;*/
  }

  .user-bubble-container,
  .account-bubble-container,
  .customer-bubble-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .user-bubble-container,
  .customer-bubble-container {
    // border: 1px solid #54657b;
  }

  padding: 8px;

  .account-bubble {
    border: 1px solid black;
    border-radius: 0;
    padding: 20px;
    font-size: 1rem;
    margin: 0px;
    align-self: stretch;
  }
}

.connection {
  background: black;
  display: block;
  &.vertical {
    width: 2px;
    height: 50px;
  }

  &.horizontal {
    height: 2px;
    width: 50px;
  }
}

.association-container {
  .options {
    max-height: 250px;
    overflow: auto;
  }
}
.intake-export {
  // padding: 0 0.5rem;
  display: flex;
  flex-direction: column;

  .box {
    max-width: 32rem;
  }
  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }
  .intake-export-desc {
    font-weight: 600;
  }
  .intake-export-text {
    font-style: italic;
    color: #6a727b;
  }
  .intake-export-note {
    max-width: 32rem;
    margin-bottom: 0;
  }

  // padding: 0 1.5rem;

  button {
    max-width: 16rem;
    margin: 1rem 0;
  }
}
