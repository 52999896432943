.calendar-container {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 20px;
  transition: 0.3s;
  position: relative;
  //top: 300px;
  //left: 300px;

  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    z-index: 100;
    width: 100vw;
    border-radius: 0;
    height: 100vh;

    .y-scroll-container {
      height: 100vh;
    }
  }
}

.calendar-controlbar {
  display: flex;
  flex-direction: column;
  z-index: 1;
  top: 0;
  background: white;

  .date-controls button:not(.today-button),
  .fullscreen-button {
    font-size: 1rem;
    padding: 0;
    width: 32px;
    height: 32px;
  }
}

.grid-item {
  flex: 1 1 75px;
  width: 75px;
  min-width: 75px;

  &.day-grid {
    border-right: 1px solid lightgray;
  }

  .hour-grid {
    height: 50px;
    border-bottom: 1px solid lightgray;
    width: 100%;
  }
}

@media only screen and (max-width: 780px) {
  .grid-item {
    flex: 1 1 30px;
    width: 30px;
  }
}

.y-scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: scroll;
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.x-scroll-container {
  position: relative;
  display: flex;
}

#calendar-view,
#calendar-tz {
  width: 100px;
  height: 36px;
  padding: 0;
  font-size: 12px;
}

.date-labels {
  position: sticky;
  min-width: 100%;
  opacity: 0.92;
  top: 0;
  z-index: 4;
  background: white;
  flex: 0 0 50px;
  margin: auto;
  padding-left: 50px;

  .day-header {
    text-align: center;
    text-transform: uppercase;

    &.today {
      color: #37bec3;
    }

    p {
      margin-bottom: 0;

      &.day-of-week {
        font-weight: 200;
      }

      &.date-of-month {
        font-size: 20px;
        &.today {
          border-radius: 50px;
          background-color: #37bec3;
          color: white;
          display: inline-block;
          padding: 0 8px;
        }
      }
    }
  }
}

.hour-labels {
  flex: 0 0 50px;
  background: #ffffffee;
  height: 100%;
  position: sticky;
  margin-top: 36px;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  left: 0;
  z-index: 2;

  .hour-grid {
    border-bottom: none;
    text-align: right;
    font-weight: 200;
    padding-right: 10px;
    height: 50px;
  }
}

.calendar-block {
  position: absolute;
  color: white;
  min-height: 20px;
  line-height: 20px;
  width: 90%;
  overflow: hidden;
  text-overflow: hidden;
  font-size: 14px;
  opacity: 1;
  cursor: pointer;
  line-height: 14px;
  white-space: nowrap;
  padding: 2px;
  border-radius: 2px;
  transition: 0.2s;

  &.free {
    background-color: #37bec3; //#37c35b;
    z-index: 0;
  }

  &.busy {
    background-color: #ff777d;
    z-index: 1;
  }

  &.appointment {
    background-color: #0d797c;
    z-index: 2;

    &:hover {
      min-height: 50px;
    }
  }

  &:hover {
    min-height: 65px;
    width: 100%;
    border: 1px solid white;
    transform: scale(1.02);
    box-shadow: 0px 1px 4px 1px #0000003b;
  }

  &.in-the-past {
    opacity: 0.7;
    cursor: auto;
  }

  &.now-indicator {
    box-shadow: none;
    border: none;
    padding: 0;
    background-color: black;
    min-height: 2px;
    z-index: 3;
    width: 100%;
  }
}

.edit-availability-modal-interior {
  .tab-select {
    align-items: stretch;

    input[value='busy']:checked + label {
      background-color: red;
    }

    .container {
      width: 50%;
    }
  }

  input[type='date'],
  input[type='time'] {
    width: 50px;
  }
}

.custom-recurrence-section {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(220, 224, 224);

  input[type='number'] {
    min-width: 50px;
  }

  .checkbox {
    [type='checkbox'] {
      display: none;

      &:checked {
        & + label {
          color: #fff;

          &::before {
            background: #37bec3;
          }
        }
      }

      & + label::before {
        border-radius: 30px;
        margin-right: -24px;
        background: #dce0e0;
      }

      & + label::after {
        display: none;
      }
    }
  }

  .field.days-of-week {
    width: 36px;
    display: inline-block;
  }

  .recurrence-ending-container {
    label {
      display: flex;
      height: 50px;
      align-items: center;

      &::before,
      &::after {
        margin-top: 15px;
      }
    }
  }
}

.appointment-item {
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 1rem;

  p {
    margin: 0.2rem;
  }

  .message {
    position: relative;
    padding: 8px;
    border: 1px solid grey;
    border-radius: 8px;
    max-width: 70%;

    .message-unread-notification {
      top: -5px;
      right: -5px;
      width: 10px;
      height: 10px;
      position: absolute;
      background: red;
      border-radius: 26px;
    }

    p {
      margin-bottom: 0;

      &.message-date {
        font-weight: 300;
        font-size: 0.9rem;
      }
    }
  }
}

.appointment-view-container {
  .box.inner-container {
    border-radius: 0px 5px;
    z-index: 1;
    position: relative;
    background: white;
  }

  .appointment-tab {
    border-radius: 0;
    background: white;
    color: black;
    position: relative;
    top: 0px;
    z-index: 0;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #00000024;
    border-radius: 5px 5px 0px 0px;
    opacity: 0.6;

    h5 {
      margin: 0;
    }

    &.active {
      z-index: 3;
      opacity: 1;
    }
  }

  .appointment-day-group:not(:last-child) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid lightgrey;
  }
}

.page-link {
  &.active {
    border-radius: 20px;
    width: 24px;
    height: 24px;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    line-height: 24px;
    background-color: #6ed0d3;
    color: white;
    box-shadow: 0 2px 2px 1px #0000001a;
  }

  text-decoration: none;
  color: #2c3643;
  cursor: pointer;
}

.pagination {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 45rem;
}

.page-button {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  padding-right: 1px;
  border: 1px solid #37bec3;
  box-shadow: 0 2px 2px 1px #0000001a;
  cursor: pointer;

  &.forwards {
    padding-left: 3px;
  }

  &.backwards {
    padding-right: 1px;
  }
}

@media only screen and (max-width: 780px) {
  .appointment-view-container {
    .appointment-item {
      flex-wrap: wrap;
      border: 1px solid lightgrey;
      padding: 8px;
    }

    .appointment-tab {
      opacity: 0.3;
    }
  }
}
