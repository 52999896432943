.omni-search-nav-input {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    align-self: center;
    outline: 2px solid #dce0e0;
    border-radius: 50px;
}

@media (max-width: 899px) {
    .omni-search-nav-input {
        display: none;
    }
}
