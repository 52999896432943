.reimbursify {
    padding: 0 1rem;
    h2,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    h2 {
        margin-top: 1.5rem;
    }

    h4 {
        margin-bottom: 1rem;
    }

    h5 {
        margin-bottom: 1rem;
    }

    h6 {
        margin-bottom: 0.5rem;
    }

    .reimbursify-code-widget {
        code {
            padding: 0.25rem;
        }
        .reimbursify-code-display {
            display: flex;
            justify-content: space-between;
        }
        .reimbursify-code-widget-inputs {
            form {
                padding: 0;

                div {
                    display: flex;
                    align-items: center;
                }
            }

            display: flex;
            justify-content: space-between;

            & > * {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            & > div {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
            }

            input.checkbox:checked + label.checkbox {
                background: #37bec3;
            }
        }

        .copy-button {
            padding: 0;
            background-color: transparent;
            border: none;
            height: auto;
            color: inherit;
            font-size: 1rem;
            margin-left: 0.5rem;
        }
    }

    .npi-numbers-widget {
        .npi-numbers {
            // padding: 0.25rem;
            // border: 1px solid gray;
            // border-radius: 5px;
            margin-bottom: 1rem;
            width: 100%;

            th,
            td {
                text-align: center;

                i {
                    opacity: 0.5;
                }
            }
        }

        form {
            padding: 0;
        }

        label {
            display: flex;
            flex-direction: column;
        }

        .npi-inputs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            margin-bottom: 1rem;
        }
    }
}
