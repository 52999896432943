#flashes {
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 300px;

  .alert {
    strong {
      color: #fff;
      display: inline-block;
      margin-bottom: 5px;
    }

    p {
      margin: 0;
      color: rgba(255, 255, 255, 0.5);
    }

    small {
      font-size: 8px;
      position: absolute;
      right: 5px;
      bottom: 5px;
      color: rgba(255, 255, 255, 0.3);
    }

    .close a {
      text-decoration: none;
    }
  }
}
